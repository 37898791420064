import {bootstrapApplication} from '@angular/platform-browser';
import {appConfig} from './app/app.config';
import {AppComponent} from './app/app.component';
import {environment} from './environments/environment';
import {enableProdMode} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import de from '@angular/common/locales/de';

if (environment.production) {
  enableProdMode();
}

registerLocaleData(en);
registerLocaleData(de);

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
